.App {
  text-align: center;
}

.logo {
  font-family: Paytone One, sans-serif;
  font-size: 2rem;
}

header a {
  text-decoration: none;
  transition: all 250ms ease;
  position: relative;
  transition: clip-path 275ms ease;
}

header a:hover span::before,
header a:focus span::before {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  color: rgba(235, 228, 223, 1);
}

header a span {
  position: relative;
  display: inline-block;
  color: rgba(255, 248, 243, 1);
}

header a span::before {
  position: absolute;
  content: "Let's Plan";
  text-decoration: underline;
  text-decoration-style: wavy;
  text-decoration-color: rgba(235, 228, 223, 1);
  clip-path: polygon(0 0, 0 0, 0% 100%, 0 100%);
  transition: clip-path 250ms ease;
}

main {
  margin-top: 5rem;
  min-height: 90vh;
}

footer {
  margin-top: 2rem;
}
